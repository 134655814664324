import { graphql } from 'gatsby'
import React, { FunctionComponent } from 'react'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import EasySoftwareBreadcrumbs, {
  BreadcrumbItem,
  breadcrumbsOnTopImage,
} from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import { WidgetPuxSectionType } from 'Shared/components/widgets/WidgetPuxSection'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'

import Layout from '../components/core/Layout'

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      easySoftwarePage(first: 1, where: { path: $pagePath }) {
        displayText
        localization {
          localizations {
            ... on Orchard_EasySoftwarePage {
              path
              published
              localization {
                culture
              }
            }
          }
        }
      }
    }
  }
`

interface PageType {
  displayText: string
  localization: {
    localizations: localizedPathType[]
  }
}

interface EasySoftwarePageType {
  data: {
    orchard: {
      easySoftwarePage: [PageType]
    }
  }
  pageContext: {
    breadcrumbs: [BreadcrumbItem]
    widgets: {
      widgetZone: [WidgetPuxSectionType]
    }
    metaTags: PuxMetaTagsType
  }
}

const EasySoftwarePage: FunctionComponent<EasySoftwarePageType> = ({
  data,
  pageContext,
}) => {
  const breadCrumbsData = pageContext.breadcrumbs
  return (
    <Layout
      customLayoutClass={
        breadcrumbsOnTopImage(pageContext.widgets.widgetZone) &&
          breadCrumbsData?.length
          ? `has-topimage`
          : ``
      }
      localizedPath={
        data.orchard.easySoftwarePage[0].localization?.localizations
      }
    >
      <EasySoftwareBreadcrumbs items={breadCrumbsData} />
      <PuxMetaTags {...pageContext.metaTags} />
      <WidgetBuilder widgetBuilderData={pageContext?.widgets?.widgetZone} />
    </Layout>
  )
}

export default EasySoftwarePage
